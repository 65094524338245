let slick = {
  'top': {},
  'select': {},
  'flowpack': {},
  'item': {},
  'cart': {}
};

let windowSize = "PC";

function optimizeByWindowSize(valueOfPC, valueOfTab, valueOfSP = '') {
  let val;
  if(window.innerWidth < 768) {
    val = valueOfSP || valueOfTab;
  } else if(window.innerWidth < 1025) {
    val = valueOfTab;
  } else  {
    val = valueOfPC;
  }
  return val;
}

// 矢印の位置を動的に調整
function allowPositionCalculator(slider, img, arrow) {
  let slideWrapH = $(slider).height();
  let slideImgH = $(img).height();
  let calcTextAreaH = slideWrapH - slideImgH;
  let allowPosition = (slideWrapH - calcTextAreaH) / 2;

  $(arrow).css(
    'top',
    `${allowPosition}px`
  );
}

// TOPページ ローテーションバナー
slick.top.rotationBanners = function() {
    $('.site-banners-TOP_ROTATION_01 .banners ul').slick({
      autoplaySpeed: 5000,
      infinite: true,
      cssEase: 'ease',
      centerMode: true,
      centerPadding: '0',
      arrows: true,
      dots: true,
      prevArrow: '<button onclick="tag.clickEventGA4(\'carousel\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
      nextArrow: '<button onclick="tag.clickEventGA4(\'carousel\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
  
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            centerPadding: '0',
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: '3.56234097%',
          },
        },
      ]
    });
  
    $('.site-banners-TOP_ROTATION_SP_01 .banners ul').not('.slick-initialized').slick({
      autoplaySpeed: 5000,
      infinite: true,
      cssEase: 'ease',
      centerMode: true,
      centerPadding: '0',
      arrows: true,
      dots: true,
      prevArrow: '<button onclick="tag.clickEventGA4(\'carousel\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
      nextArrow: '<button onclick="tag.clickEventGA4(\'carousel\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
  
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            centerPadding: '0',
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: '3.56234097%',
          },
        },
      ]
    });
}

// TOPページ サイトバナー アイコンリスト
slick.top.iconList = function() {
  const $iconlist = $('.site-banners-TOP_ICON_LIST');
  if ($iconlist.length) {
    $iconlist.find('.banners ul').slick({
      autoplay: false,
      infinite: true,
      slidesToShow: 5,
      arrows: true,
      prevArrow: '<button onclick="tag.clickEventGA4(\'iconlist\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" style="">Previous</button>',
      nextArrow: '<button onclick="tag.clickEventGA4(\'iconlist\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="">Next</button>',
  
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
          },
        },
      ]
    });
  }
}

// TOPページ TOPICS ナビゲーション
slick.top.topicsNavigation = function() {
  if ($('.p-topics__navi')) {
    $('.p-topics__navi').slick({
      autoplay: false,
      infinite: false,
      slidesToShow: 5,
      arrows: true,
      variableWidth: true,
  
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          },
        },
      ]
    });
  }
}

// TOPページ TOPICS 記事一覧
slick.top.topics = function() {
  if ($('#deago-top-news').length) {
    let flag = '';
    const bp = 768;

    function slickPC() {
      $('#deago-top-news .top_news__entry_list')
        .slick({
          autoplay: false,
          centerMode: false,
          infinite: false,
          slidesToShow: 4,
          arrows: true,
          prevArrow: '<button onclick="tag.clickEventGA4(\'topics\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
          nextArrow: '<button onclick="tag.clickEventGA4(\'topics\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
        });
    }

    function slickSP() {
      $('#deago-top-news .top_news__entry_list')
        .slick({
          autoplay: false,
          slidesToShow: 1,
          slidesPerRow: 2,
          rows: 2,
          arrows: true,
          prevArrow: '<button onclick="tag.clickEventGA4(\'topics\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
          nextArrow: '<button onclick="tag.clickEventGA4(\'topics\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
        });
    }

    if (window.innerWidth < bp) {
      slickSP();
      flag = 'sp';
    } else {
      slickPC();
      flag = 'pc';
    }

    $(window).on('resize', function () {
      if (window.innerWidth < bp && flag == 'pc') {
        $('#deago-top-news .top_news__entry_list').slick('unslick');
        slickSP();
        flag = 'sp';
      } else if (window.innerWidth >= bp && flag == 'sp') {
        $('#deago-top-news .top_news__entry_list').slick('unslick');
        slickPC();
        flag = 'pc';
      }
    });
  }
}

// TOPページ SPECIAL
slick.top.specialItem = function() {
  if ($('.top-special .site-banners .banners ul').length) {
    let flag = '';
    const bp = 769;

    function slickPC() {
      $('.top-special .site-banners .banners ul')
        .not('.slick-initialized')
        .slick({
          autoplay: false,
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          centerMode: false,
          prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
          nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
        }).css('display', 'block');
    }

    function slickSP() {
      if ($('.top-special .site-banners .banners ul').hasClass('slick-initialized')) {
        $('.top-special .site-banners .banners ul').slick('unslick');
      }
      $('.top-special .site-banners .banners ul').css('display', 'block');
    }

    function checkResize() {
      if (window.innerWidth < bp && flag == 'pc') {
        $('.top-special .site-banners .banners ul').slick('unslick');
        slickSP();
        flag = 'sp';
      } else if (window.innerWidth >= bp && flag == 'sp') {
        $('.top-special .site-banners .banners ul').css('display', 'none');
        setTimeout(() => {
          slickPC();
        }, 100);
        flag = 'pc';
      }
    }

    if (window.innerWidth < bp) {
      slickSP();
      flag = 'sp';
    } else {
      slickPC();
      flag = 'pc';
    }

    $(window).on('resize', checkResize);
  }
};

// セレクトショップTOP FV
slick.select.rotationBanners = function() {
  $('.select-top .p-fv__slider').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    cssEase: 'ease',
    centerMode: true,
    centerPadding: '0',
    arrows: true,
    dots: true,
    prevArrow: '<button onclick="tag.clickEventGA4(\'carousel\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
    nextArrow: '<button onclick="tag.clickEventGA4(\'carousel\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
  
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerPadding: '0',
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '3.56234097%',
        },
      },
    ]
  });
}

// セレクトショップTOP あなたへおすすめの特集
slick.select.recommendSpecialFeature = function() {
  $('.select-top .p-edition .l-slider').slick({
    autoplay: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
  
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ]
  });
}

// セレクトショップTOP 刊行シリーズから探す
slick.select.searchBySeries = function() {
  if ($('.select-top .p-publication').length) {
    let flag = '';
    const bp = 768;

    function slickPC() {
      $('.select-top .p-publication .l-slider')
        .not('.slick-initialized')
        .slick({
          autoplay: false,
          infinite: false,
          slidesToShow: optimizeByWindowSize(1,2),
          slidesPerRow: 3,
          rows: 2,
          arrows: true,
          prevArrow: '<button onclick="tag.clickEventGA4(\'series\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
          nextArrow: '<button onclick="tag.clickEventGA4(\'series\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
        });
    }

    function slickSP() {
      $('.select-top .p-publication .l-slider')
        .not('.slick-initialized')
        .slick({
          autoplay: false,
          infinite: false,
          slidesToShow: 1,
          slidesPerRow: 2,
          rows: 3,
          arrows: true,
          prevArrow: '<button onclick="tag.clickEventGA4(\'series\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
          nextArrow: '<button onclick="tag.clickEventGA4(\'series\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
        });
    }

    if (window.innerWidth < bp) {
      slickSP();
      flag = 'sp';
    } else {
      slickPC();
      flag = 'pc';
    }

    $(window).on('resize', function () {
      if (window.innerWidth < bp && flag == 'pc') {
        $('.select-top .p-publication .l-slider').slick('unslick');
        slickSP();
        flag = 'sp';
      } else if (window.innerWidth >= bp && flag == 'sp') {
        $('.select-top .p-publication .l-slider').slick('unslick');
        slickPC();
        flag = 'pc';
      }
    });
  }
}

// セレクトショップTOP お楽しみいただけそうな商品
slick.select.entertainItem = function() {
  if ($('.select-top .p-pleasure').length) {
    let flag = '';
    const bp = 769;

    function slickPC() {
      $('.select-top .p-pleasure .l-slider')
        .not('.slick-initialized')
        .slick({
          autoplay: false,
          infinite: true,
          slidesToShow: optimizeByWindowSize(4, 3),
          slidesToScroll: optimizeByWindowSize(4, 3),
          arrows: true,
          prevArrow: '<button onclick="tag.clickEventGA4(\'recommend\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
          nextArrow: '<button onclick="tag.clickEventGA4(\'recommend\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
        });
    }

    function slickSP() {
      $('.select-top .p-pleasure .l-slider').not('.slick-initialized').slick({
        autoplay: false,
        infinite: false,
        slidesToShow: 1,
        slidesPerRow: 1,
        rows: 3,
        arrows: true,
        prevArrow: '<button onclick="tag.clickEventGA4(\'recommend\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
        nextArrow: '<button onclick="tag.clickEventGA4(\'recommend\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
      });
    }

    if (window.innerWidth < bp) {
      slickSP();
      flag = 'sp';
    } else {
      slickPC();
      flag = 'pc';
    }

    $(window).on('resize', function () {
      if (window.innerWidth < bp && flag == 'pc') {
        $('.select-top .p-pleasure .l-slider').slick('unslick');
        slickSP();
        flag = 'sp';
      } else if (window.innerWidth >= bp && flag == 'sp') {
        $('.select-top .p-pleasure .l-slider').slick('unslick');
        slickPC();
        flag = 'pc';
      }
    });
  }
}

// セレクトショップTOP おすすめ関連商品
slick.select.recommendItem = function() {
  $('.select-top .p-recommend .panel-body > .item-display').slick({
    autoplay: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    prevArrow: '<button onclick="tag.clickEventGA4(\'related\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
    nextArrow: '<button onclick="tag.clickEventGA4(\'related\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ]
  });
}

// セレクトショップTOP 最近チェックした商品
slick.select.recentCheckedItem = function() {
  $('.select-top .p-history .panel-body > .item-display').slick({
    autoplay: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    prevArrow: '<button onclick="tag.clickEventGA4(\'check\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
    nextArrow: '<button onclick="tag.clickEventGA4(\'check\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
    ]
  });
}

// パックトイ ローテーションバナー
slick.flowpack.rotationBanners = function() {
  $('.site-banners-FLOWPACK_ROTATION_01 .slider').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    cssEase: 'ease',
    centerMode: true,
    centerPadding: '0',
    arrows: true,
    dots: true,
    prevArrow: '<button onclick="tag.clickEventGA4(\'carousel\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
    nextArrow: '<button onclick="tag.clickEventGA4(\'carousel\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
  
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerPadding: '0',
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '3.56234097%',
        },
      },
    ]
  });
}

// パックトイ サムネイル
slick.flowpack.thumbnails = function() {
  $('.site-banners-FLOWPACK_ROTATION_01 .thumbnail').slick({
    centerMode: true,
    centerPadding: 40,
    slidesToShow: 4,
    variableWidth: false,
    focusOnSelect: false,
    prevArrow: '<button onclick="tag.clickEventGA4(\'kids_icon\', \'prev\', \'\', \'\')" class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style="">Previous</button>',
    nextArrow: '<button onclick="tag.clickEventGA4(\'kids_icon\', \'next\', \'\', \'\')" class="slick-next slick-arrow" aria-label="Next" type="button" style="" aria-disabled="false">Next</button>',
  
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          variableWidth: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
        },
      },
    ]
  }); 
}

// パックトイ スライダーロゴ
slick.flowpack.sliderLogo = function() {
  if ($('.site-banners-FLOWPACK_ROTATION_01 .slider-logo').length) {
    $('.site-banners-FLOWPACK_ROTATION_01 .slider-logo').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      cssEase: 'ease',
      centerMode: true,
      centerPadding: '8.3333333%',
      slidesToShow: 4,
      arrows: true,
      dots: false,
  
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            centerPadding: '8.3333333%',
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: '3.56234097%',
            slidesToShow: 3,
          },
        },
      ]
    });
  }
}

// パートワーク商品詳細 メインスライド
slick.item.partworkMain = function() {
  if ($('.partwork-product .p-product__display').length) {
    $(
      '.partwork-product .p-product__display .p-product__display-slides'
    ).slick({
      autoplay: false,
      infinite: true,
      slidesToShow: 1,
      arrows: true,
      dots: true,
      appendDots: '.partwork-product .p-product__display-dots',
      customPaging: function (slick, index) {
        let targetImage = slick.$slides.eq(index).find('img').attr('src');
        return '<img src=" ' + targetImage + ' "/>';
      },
  
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            arrows: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
      ]
    });
  }
}

// パートワーク商品詳細 最近チェックした商品
slick.item.partworkRecentCheckedItem = function() {
  if ($('.partwork-product + .p-history .item-display.browsed').length) {
    $('.item-display.browsed .panel-body > .item-display').not('.slick-initialized').slick({
      autoplay: false,
      infinite: true,
      slidesToShow: 4,
      arrows: true,
      centerMode: false,
      centerPadding: '19.3384224vw',

      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            centerMode: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ]
    });
  }
}

// パートワーク商品詳細 その他の号はこちら
slick.item.partworkOtherIssue = function() {
  if ($('.partwork-product .p-product__issue').length) {
    $('.partwork-product .p-product__issue .p-product__issue-slides').not('.slick-initialized').slick({
      autoplay: false,
      infinite: true,
      slidesToShow: 4,
      arrows: true,
      centerMode: false,
      centerPadding: '19.3384224vw',

      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            centerMode: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ]
    });
  }
};

// セレクトショップ商品詳細 メインスライド
slick.item.selectMain = function() {
  if ($('.select-product .p-product').length) {
    $('.select-product .p-product .p-product__display-slides').not('.slick-initialized').slick({
      autoplay: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      variableWidth: false,
      appendDots: '.select-product .p-product__display-dots',
      customPaging: function (slick, index) {
        let targetImage = slick.$slides.eq(index).find('img').attr('src');
        return '<img src=" ' + targetImage + ' "/>';
      },
    });
  }
}

// セレクトショップ商品詳細 関連商品
slick.item.selectRelatedItem = function() {
  if ($('.select-product .p-relation').length) {
    $('.select-product .p-relation__list').not('.slick-initialized').slick({
      autoplay: false,
      infinite: false,
      slidesToShow: 4,
      arrows: true,

      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
      ]
    });

    allowPositionCalculator(
      '.select-product .p-relation .slick-slider:visible',
      '.select-product .p-relation .p-relation__item-img:visible',
      '.select-product .p-relation .slick-arrow'
    );
  }
}

// セレクトショップ商品詳細 他のお客様が閲覧している商品
slick.item.selectPopularItem = function() {
  if ($('.select-product .p-other').length) {
    $('.select-product .p-other .panel-body > .item-display').not('.slick-initialized').slick({
      autoplay: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: true,

      responsive: [
        {
          breakpoint: 1025,
          settings: {
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
      ]
    });

    allowPositionCalculator(
      '.select-product .p-other .panel-body > .item-display',
      '.select-product .p-other .item-thumbnail-img-wrapper',
      '.select-product .p-other .slick-arrow'
    );
  }
}

// セレクトショップ商品詳細 最近チェックした商品
slick.item.selectRecentCheckedItem = function() {
  if ($('.select-product .p-history').length) {
    $('.select-product .p-history .panel-body > .item-display').not('.slick-initialized').slick({
      autoplay: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: true,

      responsive: [
        {
          breakpoint: 1025,
          settings: {
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 2,
          },
        },
      ]
    });

    allowPositionCalculator(
      '.select-product .p-history .panel-body > .item-display',
      '.select-product .p-history .item-thumbnail-img-wrapper',
      '.select-product .p-history .slick-arrow'
    );
  }
}

// カート おすすめ関連商品
slick.cart.recommendItem = function() {
  if ($('.display-box-related-cart').length) {
    $('.display-box-related-cart .item-display').slick({
      autoplay: false,
      infinite: true,
      slidesToShow: 4,
      arrows: true,
      centerMode: false,
      centerPadding: '19.3384224vw',

      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            centerMode: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ]
    });
  }
}

// onloadで実行
slick.init = function() {
  slick.top.rotationBanners();
  slick.top.iconList();
  slick.top.topicsNavigation();
  slick.top.specialItem();

  slick.select.rotationBanners();
  slick.select.recommendSpecialFeature();
  slick.select.searchBySeries();
  slick.select.entertainItem();

  slick.flowpack.rotationBanners();
  slick.flowpack.thumbnails();
  slick.flowpack.sliderLogo();

  slick.item.partworkMain ();
  slick.item.partworkRecentCheckedItem();
  slick.item.selectMain();
  slick.item.selectRelatedItem();
  slick.item.selectPopularItem();
  slick.item.selectRecentCheckedItem();

  slick.cart.recommendItem();
}
